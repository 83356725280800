const rootStyles = getComputedStyle(document.documentElement);

export function isTailwindColor(val: string): boolean {
    return !!rootStyles.getPropertyValue(`--colors-${val}`);
}

/**
 * Resolves tailwind color names to native css color values
 */
export function resolveTailwindColor(val?: string): string | undefined {
    if (!val) return undefined;

    if (isTailwindColor(val)) {
        return `rgb(var(--colors-${val}))`;
    }

    return val;
}
