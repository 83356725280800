<template>
    <div class="w-64 bg-white shadow rounded-lg overflow-visible">
        <div class="cover">
            <span
                v-if="_karmaPoints"
                class="bg-purple-100 text-purple-400 absolute right-[8px] top-[8px] rounded-md px-1 text-xs leading-5"
                v-text="_karmaPoints"
            ></span>
            <a
                :href="_profileUrl"
                class="relative flex justify-center avatar text-center rounded-full top-[20px] mx-auto border-4 border-white w-30 h-30"
                @click.capture="handleClick"
            >
                <AssetLottie
                    v-if="_animatedAvatar"
                    :src="_animatedAvatar"
                    :class="['avatar-container']"
                ></AssetLottie>
                <img
                    v-else
                    :class="['avatar-container']"
                    :src="_avatar"
                    :alt="_name"
                />
                <div
                    v-if="_isAdmin"
                    class="absolute bottom-0 px-1.5 bg-blue-500 rounded leading-3 text-center text-3xs text-grey-100"
                >
                    {{ $t('common:admin')?.toUpperCase() }}
                </div>
            </a>
        </div>
        <div
            v-if="userStats"
            class="grid grid-cols-3 gap-x-1 px-4 mt-10"
        >
            <Tag
                icon="upload"
                color-class="bg-green-100 text-positive"
                :size="TagSize.SM"
                :text="userStats.uploads"
            />
            <Tag
                icon="downloads"
                color-class="bg-yellow-100 text-yellow-400"
                :size="TagSize.SM"
                :text="userStats.downloads"
            />
            <Tag
                icon="badge-karma"
                color-class="bg-purple-300 text-purple-500"
                :size="TagSize.SM"
                :text="userStats.karma"
            />
        </div>
        <div :class="[userStats ? 'pt-2 ' : 'pt-10 pb-6', 'text-center px-5']">
            <Spinner
                v-if="(!_name || !_universityName) && isLoading"
                color="blue"
                appearance="circular"
                size="xs"
            />
            <template v-else>
                <a
                    :href="_profileUrl"
                    class="text-xl font-medium username-truncate"
                    @click.capture="handleClick"
                    v-text="_name"
                ></a>
                <div
                    v-if="_universityName"
                    class="text-sm leading-5 font-normal"
                    v-text="_universityName"
                ></div>
            </template>
        </div>
        <div class="buttons px-3 pb-3">
            <BtnMenuShare
                v-if="!hideShareButton"
                class="!w-full"
                menu-class="!w-full"
                :share-url="_profileUrl"
                :share-title="$t('share.profileShare', { name: _name })"
                :label="$t('share.tooltip')"
                variant="outlined"
                color="primary"
            />
            <Btn
                v-if="!disableChatButton"
                data-testid="chat-button_profile-card"
                class="block w-full mt-2"
                icon="chat"
                color="primary"
                :to="{ name: 'chats.user', params: { id: userId } }"
                :title="$t('chat.chatUserBtn')"
                :label="$t('chat.message')"
            >
            </Btn>
        </div>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue';
import AssetLottie from '@components/AssetLottie';
import Btn from '@components/Btn';
import Spinner from '@components/Spinner';
import type { UniversityBaseResource } from '@/api/backend/resources/UniversityResource';
import type { UserProfileResource } from '@/api/gateway/legacy-api/resources/UserProfileResource';
import type { ContributorStatsResource } from '@api/gateway/legacy-api/resources/ContributorsResource';
import { getUserProfile } from '@/api/gateway/legacy-api/profile';
import BtnMenuShare from '@components/BtnMenuShare/BtnMenuShare.vue';
import Tag, { TagSize } from '@/components/Tag';

/**
 * This profile card is a floating element that is displayed when the visitor hovers the mouse on the user name container.
 * It includes the user name, university name, and buttons to share a profile and start a chat with the user.
 * BtnMenuShare component is used to render the share menu, and Btn for chat button.
 */
export default defineComponent({
    name: 'ProfileCard',
    i18nOptions: {
        namespaces: ['common'],
    },
    components: {
        BtnMenuShare,
        AssetLottie,
        Btn,
        Spinner,
        Tag,
    },
    props: {
        /**
         * User id
         * @example 394261
         */
        userId: {
            type: Number,
            required: true,
        },
        /**
         * Total karma points of user
         * @example 2500
         */
        karmaPoints: {
            type: Number,
            default: 0,
        },
        /**
         * user stats object that contains total uploads,downloads and karma of the user
         * @example { uploads: 3, downloads: 43, karma: 1234 }
         */
        userStats: {
            type: Object as PropType<ContributorStatsResource>,
            default: null,
        },
        /**
         * User profile url
         * @example https://www.studydrive.net/de/profile/sam/394261
         */
        profileLink: {
            type: String,
            default: null,
        },
        /**
         * User profile name
         * @example Sam
         */
        name: {
            type: String,
            default: null,
        },
        /**
         * Admin badge
         */
        showAdminBadge: {
            type: Boolean,
            default: false,
        },
        /**
         * User avatar url
         * @example https://www.studydrive.net/avatars/sam.jpg
         */
        avatar: {
            type: String,
            default: null,
        },
        /**
         * User animated avatar url
         * @example https://www.studydrive.net/avatars/animated.jpg
         */
        animatedAvatar: {
            type: String,
            default: null,
        },
        /**
         * University UniversityBaseResource object including name, id
         * @example {id: 232762, name: "RWTH Aachen"}
         */
        university: {
            type: Object as PropType<UniversityBaseResource>,
            default: null,
        },
        /**
         * url to the picture of the university. will be used as background if provided
         */
        backgroundPictureUrl: {
            type: String,
            default: null,
        },
        /**
         * feature flag to disable & hide chat button
         */
        disableChatButton: {
            type: Boolean,
            default: false,
        },
        /**
         * flag to disable & hide share button
         */
        hideShareButton: {
            type: Boolean,
            default: false,
        },
        /**
         * By default, the component will load profile information and display them.
         * This can be disabled if needed.
         */
        disableProfileLoading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['view-clicked'],
    data() {
        return {
            isLoading: false,
            primaryUniversity: null as UniversityBaseResource | null,
            profile: null as UserProfileResource | null,
            TagSize,
        };
    },
    computed: {
        _universityName(): string | undefined {
            return this.primaryUniversity?.name || this.university?.name;
        },
        _profileUrl(): string {
            return this.profile?.share_link || this.profileLink;
        },
        _karmaPoints(): number {
            return this.profile?.karma_points || this.karmaPoints;
        },
        _name(): string {
            return this.profile?.full_name || this.name;
        },
        _animatedAvatar(): string | undefined {
            return this.profile?.gamify_avatar_url || this.animatedAvatar;
        },
        _avatar(): string {
            return (
                this.profile?.picture_large ||
                this.profile?.picture ||
                this.profile?.avatar_picture_large ||
                this.profile?.avatar_picture ||
                this.avatar ||
                '/images/avatars/default.svg'
            );
        },
        _isAdmin(): boolean {
            return this.profile?.is_admin ?? this.showAdminBadge;
        },
        _backgroundPicture(): string {
            return this.backgroundPictureUrl
                ? `url(${this.backgroundPictureUrl})`
                : "url('/images/backgrounds/background-brand.svg')";
        },
    },
    mounted() {
        if (!this.disableProfileLoading) {
            this.loadProfileData();
        }
    },
    methods: {
        async loadProfileData() {
            this.isLoading = true;
            this.profile = await getUserProfile(this.userId);

            const primaryStudy = this.profile.studies.find((study) => study.university.is_primary);
            if (primaryStudy) {
                this.primaryUniversity = {
                    id: primaryStudy.university.university_id,
                    name: primaryStudy.university.name,
                };
            }

            this.isLoading = false;
        },
        handleClick(e: Event) {
            e.preventDefault();
            this.$emit('view-clicked');

            // this is needed for tracking requests
            setTimeout(() => {
                // eslint-disable-next-line no-underscore-dangle
                window.location.href = this._profileUrl;
            }, 200);
        },
    },
});
</script>

<style lang="scss" scoped>
.cover {
    @apply relative h-[112px] w-full;
    &::before {
        @apply absolute inset-0 rounded-tr-lg rounded-tl-lg;
        background-image: v-bind(_backgroundPicture);
        background-size: cover;
        content: '';
    }
}
.avatar-container {
    @apply block bg-white mx-auto rounded-full;
}
.username-truncate {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>

<style>
.v-popper--theme-profileCard .v-popper__arrow-container {
    display: none;
}
.v-popper--theme-profileCard .v-popper__inner {
    @apply !overflow-visible !border-0;
}
</style>
