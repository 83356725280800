<template>
    <!-- eslint-disable vue/no-v-html -->
    <Modal
        class="border-2 border-red-200"
        should-open-in-bottom-sheet
    >
        <template #title>
            <span class="text-red-500">
                <i class="icon icon-mail w-7 h-6"></i>
                {{ $t('popup.verification.title') }}
            </span>
        </template>

        <template #content>
            <span>
                <i18next :translation="$t('popup.verification.labelSentTo')">
                    <template #email>
                        <span class="text-blue-500">
                            {{ user.email }}
                        </span>
                    </template>
                </i18next>
            </span>
            <p
                v-html="
                    $t('popup.verification.labelChange', {
                        link: linkSettingsPage,
                    })
                "
            ></p>
            <p
                class="mt-4 text-grey-500"
                v-html="$t('popup.verification.labelNotReceived')"
            ></p>
        </template>

        <template #actions>
            <!-- native modifier has been removed, please confirm whether the function has been affected  -->
            <BtnCta
                variant="secondary"
                :label="$t('popup.verification.btnCheck')"
                :loading="checkingProgress"
                @click="checkEmailVerified"
            />
            <!-- native modifier has been removed, please confirm whether the function has been affected  -->
            <BtnCta
                :label="$t('popup.verification.btnResend')"
                :loading="resendingProgress"
                @click="resendEmail"
            />
        </template>
    </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@components/Modal';
import ModalMixin from '@mixins/modalMixin';
import { verificationEmailResend, verificationEmailCheck } from '@/api/backend/auth';
import route from '@router/route';

/**
 * @emits message to display certain flash messages
 * @emits close
 */
export default {
    name: 'ModalEmailVerification',
    i18nOptions: {
        namespaces: ['common'],
    },
    components: {
        Modal,
    },
    extends: ModalMixin,
    data() {
        return {
            resendingProgress: false,
            checkingProgress: false,
            flashMessagesOption: { time: 5000, showProgress: false },
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
        linkSettingsPage() {
            return route('auth.settings');
        },
        inProgress() {
            return this.resendingProgress || this.checkingProgress;
        },
    },
    methods: {
        showMessage(appearance, content) {
            return this.$store.dispatch('flashMessages/showAlert', {
                appearance,
                content,
                progress: {
                    time: 5000,
                    showProgress: false,
                },
            });
        },
        async checkEmailVerified() {
            if (this.inProgress) {
                return;
            }
            this.checkingProgress = true;

            verificationEmailCheck()
                .then(({ data }) => {
                    if (!data.verified) {
                        this.showMessage('error', this.$t('popup.verification.successCheckNotVerified'));
                        return;
                    }

                    this.showMessage('success', this.$t('popup.verification.successCheckVerified'));
                    this.close(true);
                })
                .finally(() => {
                    this.checkingProgress = false;
                });
        },

        resendEmail() {
            if (this.inProgress) {
                return;
            }
            this.resendingProgress = true;

            verificationEmailResend()
                .then(({ data }) => {
                    this.showMessage('success', this.$t('popup.verification.successResend', { email: data.email }));
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 403:
                            this.showMessage('success', error.response?.data?.message);
                            this.close(true);
                            break;
                        case 429:
                            this.showMessage('error', this.$t('popup.verification.errorResendThrottle'));
                            break;
                        default:
                            this.showMessage('error', this.$t('errors.unexpectedResponse'));
                            break;
                    }
                })
                .finally(() => {
                    this.resendingProgress = false;
                });
        },
    },
};
</script>
