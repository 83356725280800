<template>
    <div :class="['input-field relative', $attrs.class]">
        <div class="flex absolute items-center right-0 pr-4 h-full">
            <i
                v-if="modelValue && clearable"
                class="icon icon-cancel-circled opacity-20 w-5 h-5 cursor-pointer"
                @click.prevent="() => !disabled && clear()"
            ></i>
            <div
                v-if="state && withCheckmark"
                class="ml-2 p-1 border border-grey-300 rounded-full shadow text-grey-500 leading-[0]"
            >
                <i class="icon icon-checkmark-stroke w-3 h-3"></i>
            </div>
            <component
                :is="clickableIcon ? 'button' : 'div'"
                v-else-if="icon"
                :class="['text-black w-6 h-6 ml-2', clickableIcon ? 'cursor-pointer' : 'cursor-text']"
                @click="() => clickableIcon && !disabled && $emit('click-icon')"
            >
                <slot name="icon">
                    <i :class="`icon ${icon} w-6 h-6`"></i>
                </slot>
            </component>
        </div>

        <input
            v-bind="$attrs"
            ref="input"
            :value="modelValue"
            :type="type"
            :disabled="disabled"
            :class="[
                {
                    'input-truncate': truncate,
                    success: state,
                    error: state === false,
                    'pr-17': icon && clearable,
                    'pr-10': (!clearable && icon) || (!icon && clearable),
                },
            ]"
            @input="onInput($event.target.value)"
            @blur="$emit('blur', $event)"
        />
    </div>
</template>

<script>
import { omit } from 'lodash-es';

export const INPUT_TYPE = {
    TEXT: 'text',
    PASSWORD: 'password',
    SEARCH: 'search',
    EMAIL: 'email',
    URL: 'url',
    NUMBER: 'number',
    TEL: 'tel',
};

/**
 * Basic text input component which inherits all attributes of default html text inputs.
 *
 * Used for text input.
 * Use it together with `InputContainer` to add a label and validation feedback.
 */
export default {
    name: 'InputField',
    inheritAttrs: false,

    props: {
        /**
         * The current value of the input.
         */
        modelValue: {
            type: undefined,
            default: null,
        },
        /**
         * When set to `true`, disables the input field.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Type of HTML input.
         *
         * See https://developer.mozilla.org/en-US/docs/Learn/Forms/Basic_native_form_controls#text_input_fields
         * and https://developer.mozilla.org/en-US/docs/Learn/Forms/HTML5_input_types
         */
        type: {
            type: String,
            default: 'text',
        },
        /**
         * The icon that should be disabled in the input.
         * @example icon-search
         */
        icon: {
            type: String,
            default: undefined,
        },
        /**
         * Controls the validation state appearance of the component.
         *
         * `true` for valid.
         * `false` for invalid.
         * `null` for no validation state.
         */
        state: {
            type: [Boolean, null],
            default: null,
        },
        /**
         * When set to `true` and input exists, an icon appears that allows clearing the input.
         */
        clearable: {
            type: Boolean,
            default: false,
        },
        /**
         * When set to `true`, an additional checkmark will appear when `state` is `true` and may replace the icon if present.
         */
        withCheckmark: {
            type: Boolean,
            default: false,
        },
        /**
         * When set to `true` and `icon` is specified, the icon can be clicked and will emit `click-icon`.
         */
        clickableIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * When set to `true` the input gets truncated.
         */
        truncate: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'clear',
        'update:modelValue',
        'input',
        'click-icon',
        'blur',
    ],

    computed: {
        padding() {
            let pad = this.icon || (this.state && this.withCheckmark) ? 11 : 0;
            if (this.clearable) {
                pad += pad > 0 ? 7 : 10;
            }
            return pad;
        },
        inputAttrs() {
            return omit(this.$attrs, ['class']);
        },
    },

    methods: {
        clear() {
            this.$refs.input.value = null;
            this.onInput(null);

            /**
             * Emitted when the input is cleared via the clear icon
             */
            this.$emit('clear');
        },
        focus() {
            this.$refs.input.focus();
        },
        onInput(val) {
            this.$emit('update:modelValue', val);
            this.$emit('input', val);
        },
        /**
         * Sometimes, you need to specify the input value directly where the reactivity api adds a barrier.
         * For those cases, use this function.
         * @public
         */
        setValue(value) {
            this.$refs.input.value = value;
        },
    },
};
</script>

<style scoped lang="scss">
.input-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
