import axios from 'axios';
import route from '@router/route';
import { captureException } from '@sentry/vue';

const lake = axios.create({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-SD-Internal-Token': import.meta.env.MIX_SD_LAKE_SERVICE_API_TOKEN,
        keepalive: true,
    },
});

/**
 * Stores track event data.
 *
 * @param {object} payload - The data to be stored.
 * @return void
 */
export function storeTrackEventData(payload: object): void {
    if (import.meta.env.MIX_SD_LAKE_SERVICE_DEBUG === 'true') {
        return;
    }
    lake.post(route('external.lake.events'), payload)
        // we don't want to propagate the error, but get an error report
        .catch(captureException);
}

/**
 * @deprecated OLD TRACKING SYSTEM
 * DO NOT USE ANYMORE
 */
export function storeTrackingData(payload: object): void {
    lake.post(route('external.lake.tracking'), payload)
        // we don't want to propagate the error, but get an error report
        .catch(captureException);
}
