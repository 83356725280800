<template>
    <textarea
        v-bind="$attrs"
        ref="input"
        v-dynamic-height
        :value="modelValue"
        :class="[
            'custom-scrollbar',
            {
                success: state,
                error: state === false,
                'resize-none': noResize,
            },
        ]"
        :rows="rows"
        :maxlength="maxLength"
        @input="onInput($event.target.value)"
    ></textarea>
</template>

<script>
export default {
    name: 'InputTextarea',
    inheritAttrs: false,
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        /** true: valid, false: invalid, null: neutral */
        state: {
            type: [Boolean, null],
            default: null,
        },
        rows: {
            type: Number,
            default: 4,
        },
        noResize: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            default: null,
        },
    },
    emits: ['update:modelValue', 'input'],
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        onInput(val) {
            this.$emit('input', val);
            this.$emit('update:modelValue', val);
        },
    },
};
</script>

<style lang="scss" scoped>
textarea {
    --trackColor: theme('colors.grey.100');
    margin-bottom: -7px;

    &::-webkit-scrollbar-track {
        @apply rounded-lg;
    }
}
</style>
