<template>
    <span
        data-testid="tag"
        :class="[
            ' inline-flex items-center justify-center rounded align-middle whitespace-nowrap',
            { 'text-xs': smallVariant },
            paddingClass,
            colorClass ?? defaultColorClass,
        ]"
    >
        <i
            v-if="icon"
            :class="[
                `icon icon-${icon}`,
                {
                    'mr-1': !smallVariant && hasText,
                    'mr-0.5': smallVariant && hasText,
                    'w-4 h-4': smallVariant,
                },
                iconClass,
            ]"
        ></i>
        <span
            v-if="hasText"
            :class="smallVariant ? 'font-caption-regular' : 'font-body-2-regular'"
            v-html="text"
        ></span>
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { EnumToUnion } from '@/types/misc';
import { TagSize } from './Tag';

/**
 * This is a tag component that lets you insert an icon and a text and change the colors as well as tag size.
 */
export default defineComponent({
    name: 'Tag',
    props: {
        /**
         * Provides the text of the tag.
         *
         */
        text: {
            type: [String, Number],
            default: undefined,
        },
        /**
         * Provides the icon of the tag.
         *
         */
        icon: {
            type: String,
            default: undefined,
        },
        /**
         * The size of the tag.
         * @values sm
         */
        size: {
            type: String as PropType<EnumToUnion<TagSize>>,
            default: TagSize.DEFAULT,
            validator: (val: TagSize) => Object.values(TagSize).includes(val),
        },
        /**
         * Set classes to add tag colors
         * @example "ring-orange-200 bg-orange-100 text-yellow-400"
         */
        colorClass: {
            type: String,
            default: undefined,
        },
        /**
         * Custom class for icon
         */
        iconClass: {
            type: [String, Array] as PropType<string[] | string>,
            default: null,
        },
        /**
         * Applies minimalistic styling without any padding.
         */
        bare: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        smallVariant(): boolean {
            return this.size === 'sm';
        },
        hasText() {
            return this.text !== '' && this.text !== undefined;
        },
        defaultColorClass() {
            return this.bare ? 'text-content-secondary' : 'bg-blue-200 text-primary';
        },
        paddingClass() {
            if (this.bare) return undefined;

            return {
                'p-0.5': this.smallVariant,
                'p-1': !this.smallVariant,
                'px-3': !this.smallVariant && this.hasText,
                'pl-2': !this.smallVariant && this.hasText && this.icon,
                'px-1': this.smallVariant && this.hasText,
                'px-0.5': this.smallVariant && !this.hasText,
            };
        },
    },
});
</script>
