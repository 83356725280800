const errorEl = document.createElement('div');
// required: unique id in the input element, message needs to be passed as a value
export default {
    updated(el, binding) {
        function insertAfter(newNode, referenceNode) {
            errorEl.setAttribute('id', `${el.id}-error`);
            referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
        }
        function removeError() {
            el.classList.remove('border-red-500', 'focus:border-red-500', 'hover:border-red-500');
            if (document.body.querySelector(`#${el.id}-error`)) document.body.querySelector(`#${el.id}-error`).remove();
        }

        if (binding.value) {
            el.classList.add('border-red-500', 'focus:border-red-500', 'hover:border-red-500');
            errorEl.textContent = binding.value;
            insertAfter(errorEl, el);
            errorEl.classList.add('text-red-500');
        } else if (binding.oldValue) {
            removeError();
        }
    },
};
