import { debounce } from 'lodash-es';

// eslint-disable-next-line import/prefer-default-export
export function trackStickyElement({ commit, getters }, payload) {
    const callback = () => {
        if (window.pageYOffset < payload.threshold) {
            commit(payload.mutationName, false);
        } else if (!getters[payload.getterName]) {
            commit(payload.mutationName, true);
        }
    };
    // TODO: these events need to be clean up
    /* eslint-disable tipsi/remove-event-listener */
    window.addEventListener('resize', debounce(callback, 150));
    window.addEventListener('scroll', callback);
    window.addEventListener('load', callback);
    /* eslint-enable tipsi/remove-event-listener */
}

export function trackFloatingStickyElement({ commit, getters }, payload) {
    const callback = () => {
        const el = document.getElementById(payload.observableElement);
        if (el !== undefined && el !== null) {
            const dynamicThreshold = el.getBoundingClientRect().top + window.scrollY + el.clientHeight;
            if (window.pageYOffset < dynamicThreshold) {
                commit(payload.mutationName, false);
            } else if (!getters[payload.getterName]) {
                commit(payload.mutationName, true);
            }
        } else {
            commit(payload.mutationName, false);
        }
    };
    // TODO: clean up these events
    /* eslint-disable tipsi/remove-event-listener */
    window.addEventListener('resize', debounce(callback, 150));
    window.addEventListener('scroll', callback);
    window.addEventListener('load', callback);
    /* eslint-enable tipsi/remove-event-listener */
}
