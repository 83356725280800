import type { Plugin } from 'vue';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // 5 minutes
            retry: 2,
            refetchOnWindowFocus: false,
        },
    },
});

export default {
    install(app) {
        app.use(VueQueryPlugin, { queryClient, enableDevtoolsV6Plugin: true });
    },
} as Plugin;

export const QUERY_KEYS = {
    SEMESTERS: 'semesters',
} as const;
